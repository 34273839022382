/**
 * Do not edit directly
 * Generated on Wed, 31 Jul 2024 13:06:11 GMT
 */

:root {
  --text-primary: #282d33;
  --text-secondary: #44505c;
  --text-tertiary: #637485;
  --text-primary-inverse: #ffffff;
  --text-secondary-inverse: #ebf1f7;
  --text-placeholder: #637485;
  --text-state-disabled: #899bad;
  --text-state-info: #282d33;
  --text-state-success: #009153;
  --text-state-danger: #b81919;
  --text-state-warning: #b86819;
  --text-brand-careem: #00e784;
  --text-brand-c-plus: #00493e;
  --text-brand-promotion: #d9415d;
  --text-brand-careem-pay: #00493e;
  --text-brand-careem-eat: #00493e;
  --text-brand-careem-get: #00493e;
  --text-brand-careem-go: #00493e;
  --logo-primary: #00493e;
  --logo-primary-inverse: #f6fffb;
  --logo-state-disabled: #899bad;
  --logo-state-info: #282d33;
  --logo-state-success: #00693c;
  --logo-state-warning: #b86819;
  --logo-state-danger: #b81919;
  --logo-brand-careem: #00493e;
  --logo-brand-careem-inverse: #00e784;
  --logo-brand-c-plus: #00493e;
  --logo-brand-c-plus-inverse: #00e784;
  --logo-brand-careem-pay: #001942;
  --logo-brand-careem-pay-inverse: #f6fffb;
  --logo-brand-careem-eat: #00493e;
  --logo-brand-careem-eat-inverse: #00e784;
  --logo-brand-careem-get: #00493e;
  --logo-brand-careem-get-inverse: #00e784;
  --logo-brand-careem-go: #00493e;
  --logo-brand-careem-go-inverse: #00e784;
  --background-primary: #ffffff;
  --background-secondary: #f7fafc;
  --background-tertiary: #ebf1f7;
  --background-state-disabled: #ebf1f7;
  --background-state-info-mid-emphasize: #f7fafc;
  --background-state-success-mid-emphasize: #9bfdd3;
  --background-state-warning-mid-emphasize: #fce1c5;
  --background-state-danger-mid-emphasize: #fcc5c5;
  --background-state-info-high-emphasize: #282d33;
  --background-state-success-high-emphasize: #009153;
  --background-state-warning-high-emphasize: #b86819;
  --background-state-danger-high-emphasize: #b81919;
  --background-brand-careem: #00e784;
  --background-brand-c-plus: #00493e;
  --background-brand-promotion: #d9415d;
  --background-brand-careem-pay: #001942;
  --background-brand-careem-eat: #dbffdb;
  --background-brand-careem-get: #7465f7;
  --background-brand-careem-go: #4331e8;
  --border-primary: #ebf1f7;
  --border-secondary: #f7fafc;
  --border-primary-inverse: #f6fffb;
  --border-secondary-inverse: #f7fafc;
  --border-state-disabled: #ebf1f7;
  --border-state-focused: #899bad;
  --border-state-pressed: #899bad;
  --border-state-hover: #00be6c;
  --border-state-info-mid-emphasize: #899bad;
  --border-state-info-high-emphasize: #282d33;
  --border-state-success-high-emphasize: #009153;
  --border-state-warning-high-emphasize: #b86819;
  --border-state-danger-high-emphasize: #b81919;
  --border-brand-careem: #00e784;
  --border-brand-c-plus: #00493e;
  --border-brand-promotion: #d9415d;
  --border-brand-careem-pay: #001942;
  --border-brand-careem-eat: #dbffdb;
  --border-brand-careem-get: #7465f7;
  --border-brand-careem-go: #4331e8;
  --icon-primary: #282d33;
  --icon-secondary: #44505c;
  --icon-tertiary: #637485;
  --icon-primary-inverse: #ffffff;
  --icon-secondary-inverse: #ebf1f7;
  --icon-placeholder: #637485;
  --icon-state-disabled: #899bad;
  --icon-state-info: #282d33;
  --icon-state-success: #009153;
  --icon-state-danger: #b81919;
  --icon-state-warning: #b86819;
  --icon-brand-careem: #00e784;
  --icon-brand-c-plus: #00493e;
  --icon-brand-promotion: #d9415d;
  --icon-brand-careem-pay: #00493e;
  --icon-brand-careem-eat: #00493e;
  --icon-brand-careem-get: #00493e;
  --icon-brand-careem-go: #00493e;
  --components-overlay-background: rgba(#44505c,0.60);
  --components-button-primary-text-default: #282d33;
  --components-button-primary-text-hover: #282d33;
  --components-button-primary-text-pressed: #282d33;
  --components-button-primary-text-disabled: #899bad;
  --components-button-primary-background-default: #00e784;
  --components-button-primary-background-hover: #4cf7ae;
  --components-button-primary-background-pressed: #00be6c;
  --components-button-primary-background-disabled: #ebf1f7;
  --components-button-primary-border-default: #00e784;
  --components-button-primary-border-hover: #4cf7ae;
  --components-button-primary-border-pressed: #00be6c;
  --components-button-primary-border-disabled: #ebf1f7;
  --components-button-primary-icon-default: #282d33;
  --components-button-primary-icon-hover: #282d33;
  --components-button-primary-icon-pressed: #282d33;
  --components-button-primary-icon-disabled: #899bad;
  --components-button-secondary-text-default: #ffffff;
  --components-button-secondary-text-hover: #ffffff;
  --components-button-secondary-text-pressed: #ffffff;
  --components-button-secondary-text-disabled: #899bad;
  --components-button-secondary-background-default: #637485;
  --components-button-secondary-background-hover: #899bad;
  --components-button-secondary-background-pressed: #44505c;
  --components-button-secondary-background-disabled: #ebf1f7;
  --components-button-secondary-border-default: #637485;
  --components-button-secondary-border-hover: #899bad;
  --components-button-secondary-border-pressed: #44505c;
  --components-button-secondary-border-disabled: #ebf1f7;
  --components-button-secondary-icon-default: #ffffff;
  --components-button-secondary-icon-hover: #ffffff;
  --components-button-secondary-icon-pressed: #ffffff;
  --components-button-secondary-icon-disabled: #899bad;
  --components-button-tertiary-text-default: #44505c;
  --components-button-tertiary-text-hover: #44505c;
  --components-button-tertiary-text-pressed: #44505c;
  --components-button-tertiary-text-disabled: #899bad;
  --components-button-tertiary-background-default: #ffffff;
  --components-button-tertiary-background-hover: #defcef;
  --components-button-tertiary-background-pressed: #ebf1f7;
  --components-button-tertiary-background-disabled: #ebf1f7;
  --components-button-tertiary-border-default: #c5d3e0;
  --components-button-tertiary-border-hover: #00be6c;
  --components-button-tertiary-border-pressed: #637485;
  --components-button-tertiary-border-disabled: #ebf1f7;
  --components-button-tertiary-icon-default: #44505c;
  --components-button-tertiary-icon-hover: #44505c;
  --components-button-tertiary-icon-pressed: #44505c;
  --components-button-tertiary-icon-disabled: #899bad;
  --components-button-danger-text-default: #ffffff;
  --components-button-danger-text-hover: #ffffff;
  --components-button-danger-text-pressed: #ffffff;
  --components-button-danger-text-disabled: #899bad;
  --components-button-danger-background-default: #b81919;
  --components-button-danger-background-hover: #d94141;
  --components-button-danger-background-pressed: #8e0909;
  --components-button-danger-background-disabled: #ebf1f7;
  --components-button-danger-border-default: #b81919;
  --components-button-danger-border-hover: #d94141;
  --components-button-danger-border-pressed: #8e0909;
  --components-button-danger-border-disabled: #ebf1f7;
  --components-button-danger-icon-default: #ffffff;
  --components-button-danger-icon-hover: #ffffff;
  --components-button-danger-icon-pressed: #ffffff;
  --components-button-danger-icon-disabled: #899bad;
  --components-button-counter-primary-text-default: #ffffff;
  --components-button-counter-primary-text-hover: #ffffff;
  --components-button-counter-primary-text-pressed: #ffffff;
  --components-button-counter-primary-text-disabled: #899bad;
  --components-button-counter-primary-icon-default: #ffffff;
  --components-button-counter-primary-icon-hover: #ffffff;
  --components-button-counter-primary-icon-pressed: #ffffff;
  --components-button-counter-primary-icon-disabled: #899bad;
  --components-button-counter-primary-background-default: #009153;
  --components-button-counter-primary-background-hover: #009153;
  --components-button-counter-primary-background-pressed: #009153;
  --components-button-counter-primary-background-disabled: #dde6f0;
  --components-button-counter-primary-border-default: #009153;
  --components-button-counter-primary-border-hover: #009153;
  --components-button-counter-primary-border-pressed: #009153;
  --components-button-counter-primary-border-disabled: #dde6f0;
  --components-button-counter-danger-text-default: #ffffff;
  --components-button-counter-danger-text-hover: #ffffff;
  --components-button-counter-danger-text-pressed: #ffffff;
  --components-button-counter-danger-text-disabled: #899bad;
  --components-button-counter-danger-icon-default: #ffffff;
  --components-button-counter-danger-icon-hover: #ffffff;
  --components-button-counter-danger-icon-pressed: #ffffff;
  --components-button-counter-danger-icon-disabled: #899bad;
  --components-button-counter-danger-background-default: #6b0202;
  --components-button-counter-danger-background-hover: #6b0202;
  --components-button-counter-danger-background-pressed: #6b0202;
  --components-button-counter-danger-background-disabled: #dde6f0;
  --components-button-counter-danger-border-default: #6b0202;
  --components-button-counter-danger-border-hover: #6b0202;
  --components-button-counter-danger-border-pressed: #6b0202;
  --components-button-counter-danger-border-disabled: #dde6f0;
  --components-link-primary-text-default: #44505c;
  --components-link-primary-text-hover: #009153;
  --components-link-primary-text-pressed: #44505c;
  --components-link-primary-text-disabled: #899bad;
  --components-link-primary-icon-default: #44505c;
  --components-link-primary-icon-hover: #009153;
  --components-link-primary-icon-pressed: #44505c;
  --components-link-primary-icon-disabled: #899bad;
  --components-link-success-text-default: #00693c;
  --components-link-success-text-hover: #009153;
  --components-link-success-text-pressed: #323a42;
  --components-link-success-text-disabled: #899bad;
  --components-link-success-icon-default: #00693c;
  --components-link-success-icon-hover: #009153;
  --components-link-success-icon-pressed: #323a42;
  --components-link-success-icon-disabled: #899bad;
  --components-link-danger-text-default: #b81919;
  --components-link-danger-text-hover: #d94141;
  --components-link-danger-text-pressed: #323a42;
  --components-link-danger-text-disabled: #899bad;
  --components-link-danger-icon-default: #b81919;
  --components-link-danger-icon-hover: #d94141;
  --components-link-danger-icon-pressed: #323a42;
  --components-link-danger-icon-disabled: #899bad;
  --components-input-label-text-default: #44505c;
  --components-input-label-text-default-focus: #44505c;
  --components-input-label-text-danger: #b81919;
  --components-input-label-text-danger-focus: #b81919;
  --components-input-label-text-success: #00693c;
  --components-input-label-text-success-focus: #00693c;
  --components-input-label-text-disabled: #899bad;
  --components-input-input-field-text-default: #282d33;
  --components-input-input-field-text-default-focus: #282d33;
  --components-input-input-field-text-danger: #282d33;
  --components-input-input-field-text-danger-focus: #282d33;
  --components-input-input-field-text-success: #282d33;
  --components-input-input-field-text-success-focus: #282d33;
  --components-input-input-field-text-disabled: #899bad;
  --components-input-input-field-placeholder-default: #637485;
  --components-input-input-field-placeholder-default-focus: #637485;
  --components-input-input-field-placeholder-danger: #637485;
  --components-input-input-field-placeholder-danger-focus: #637485;
  --components-input-input-field-placeholder-success: #637485;
  --components-input-input-field-placeholder-success-focus: #637485;
  --components-input-input-field-placeholder-disabled: #899bad;
  --components-input-input-field-leading-icon-default: #44505c;
  --components-input-input-field-leading-icon-default-focus: #44505c;
  --components-input-input-field-leading-icon-danger: #44505c;
  --components-input-input-field-leading-icon-danger-focus: #44505c;
  --components-input-input-field-leading-icon-success: #44505c;
  --components-input-input-field-leading-icon-success-focus: #44505c;
  --components-input-input-field-leading-icon-disabled: #899bad;
  --components-input-input-field-trailing-icon-default: #44505c;
  --components-input-input-field-trailing-icon-default-focus: #44505c;
  --components-input-input-field-trailing-icon-danger: #44505c;
  --components-input-input-field-trailing-icon-danger-focus: #44505c;
  --components-input-input-field-trailing-icon-success: #44505c;
  --components-input-input-field-trailing-icon-success-focus: #44505c;
  --components-input-input-field-trailing-icon-disabled: #899bad;
  --components-input-input-field-background-default: #ffffff;
  --components-input-input-field-background-default-focus: #ffffff;
  --components-input-input-field-background-danger: #ffffff;
  --components-input-input-field-background-danger-focus: #ffffff;
  --components-input-input-field-background-success: #ffffff;
  --components-input-input-field-background-success-focus: #ffffff;
  --components-input-input-field-background-disabled: #ebf1f7;
  --components-input-input-field-border-default: #c5d3e0;
  --components-input-input-field-border-default-focus: #899bad;
  --components-input-input-field-border-danger: #d94141;
  --components-input-input-field-border-danger-focus: #d94141;
  --components-input-input-field-border-success: #009153;
  --components-input-input-field-border-success-focus: #009153;
  --components-input-input-field-border-disabled: #ebf1f7;
  --components-input-hint-text-default: #44505c;
  --components-input-hint-text-default-focus: #44505c;
  --components-input-hint-text-danger: #b81919;
  --components-input-hint-text-danger-focus: #b81919;
  --components-input-hint-text-success: #00693c;
  --components-input-hint-text-success-focus: #00693c;
  --components-input-hint-text-disabled: #899bad;
  --components-input-hint-leading-icon-default: #44505c;
  --components-input-hint-leading-icon-default-focus: #44505c;
  --components-input-hint-leading-icon-danger: #b81919;
  --components-input-hint-leading-icon-danger-focus: #b81919;
  --components-input-hint-leading-icon-success: #00693c;
  --components-input-hint-leading-icon-success-focus: #00693c;
  --components-input-hint-leading-icon-disabled: #899bad;
  --components-circle-button-action-icon-default: #44505c;
  --components-circle-button-action-icon-selected: #00693c;
  --components-circle-button-action-icon-pressed: #44505c;
  --components-circle-button-action-icon-hover: #00693c;
  --components-circle-button-action-icon-disabled: #899bad;
  --components-circle-button-action-logo-default: #44505c;
  --components-circle-button-action-logo-selected: #00693c;
  --components-circle-button-action-logo-pressed: #44505c;
  --components-circle-button-action-logo-hover: #00693c;
  --components-circle-button-action-logo-disabled: #899bad;
  --components-circle-button-action-background-default: #ffffff;
  --components-circle-button-action-background-selected: #9bfdd3;
  --components-circle-button-action-background-pressed: #ebf1f7;
  --components-circle-button-action-background-hover: #defcef;
  --components-circle-button-action-background-disabled: #ebf1f7;
  --components-circle-button-action-border-default: #c5d3e0;
  --components-circle-button-action-border-selected: #009153;
  --components-circle-button-action-border-pressed: #637485;
  --components-circle-button-action-border-hover: #00be6c;
  --components-circle-button-action-border-disabled: #ebf1f7;
  --components-circle-button-global-icon-default: #ffffff;
  --components-circle-button-global-icon-selected: #ffffff;
  --components-circle-button-global-icon-pressed: #ffffff;
  --components-circle-button-global-icon-hover: #ffffff;
  --components-circle-button-global-icon-disabled: #899bad;
  --components-circle-button-global-logo-default: #ffffff;
  --components-circle-button-global-logo-selected: #ffffff;
  --components-circle-button-global-logo-pressed: #ffffff;
  --components-circle-button-global-logo-hover: #ffffff;
  --components-circle-button-global-logo-disabled: #899bad;
  --components-circle-button-global-background-default: #282d33;
  --components-circle-button-global-background-selected: #009153;
  --components-circle-button-global-background-pressed: #000000;
  --components-circle-button-global-background-hover: #00492a;
  --components-circle-button-global-background-disabled: #ebf1f7;
  --components-circle-button-global-border-default: #282d33;
  --components-circle-button-global-border-selected: #00492a;
  --components-circle-button-global-border-pressed: #000000;
  --components-circle-button-global-border-hover: #00492a;
  --components-circle-button-global-border-disabled: #ebf1f7;
  --components-circle-button-careem-pay-icon-default: #ffffff;
  --components-circle-button-careem-pay-icon-selected: #ffffff;
  --components-circle-button-careem-pay-icon-pressed: #ffffff;
  --components-circle-button-careem-pay-icon-hover: #ffffff;
  --components-circle-button-careem-pay-icon-disabled: #899bad;
  --components-circle-button-careem-pay-logo-default: #ffffff;
  --components-circle-button-careem-pay-logo-selected: #ffffff;
  --components-circle-button-careem-pay-logo-pressed: #ffffff;
  --components-circle-button-careem-pay-logo-hover: #ffffff;
  --components-circle-button-careem-pay-logo-disabled: #899bad;
  --components-circle-button-careem-pay-background-default: #001942;
  --components-circle-button-careem-pay-background-selected: #009153;
  --components-circle-button-careem-pay-background-pressed: #000a1a;
  --components-circle-button-careem-pay-background-hover: #00492a;
  --components-circle-button-careem-pay-background-disabled: #ebf1f7;
  --components-circle-button-careem-pay-border-default: #001942;
  --components-circle-button-careem-pay-border-selected: #00492a;
  --components-circle-button-careem-pay-border-pressed: #000a1a;
  --components-circle-button-careem-pay-border-hover: #00492a;
  --components-circle-button-careem-pay-border-disabled: #ebf1f7;
  --components-chip-primary-text-default: #44505c;
  --components-chip-primary-text-selected: #00693c;
  --components-chip-primary-text-pressed: #44505c;
  --components-chip-primary-text-hover: #00693c;
  --components-chip-primary-text-disabled: #899bad;
  --components-chip-primary-icon-default: #44505c;
  --components-chip-primary-icon-selected: #00693c;
  --components-chip-primary-icon-pressed: #44505c;
  --components-chip-primary-icon-hover: #00693c;
  --components-chip-primary-icon-disabled: #899bad;
  --components-chip-primary-background-default: #ffffff;
  --components-chip-primary-background-selected: #9bfdd3;
  --components-chip-primary-background-pressed: #ebf1f7;
  --components-chip-primary-background-hover: #defcef;
  --components-chip-primary-background-disabled: #ebf1f7;
  --components-chip-primary-border-default: #c5d3e0;
  --components-chip-primary-border-selected: #009153;
  --components-chip-primary-border-pressed: #637485;
  --components-chip-primary-border-hover: #00be6c;
  --components-chip-primary-border-disabled: #ebf1f7;
  --components-pin-medium-pin-content-leading-content-background-default: #282d33;
  --components-pin-medium-pin-content-leading-content-background-selected: #ffffff;
  --components-pin-medium-pin-content-leading-content-background-pressed: #282d33;
  --components-pin-medium-pin-content-leading-content-border-default: #282d33;
  --components-pin-medium-pin-content-leading-content-border-selected: #ffffff;
  --components-pin-medium-pin-content-leading-content-border-pressed: #282d33;
  --components-pin-medium-pin-content-leading-content-icon-default: #ffffff;
  --components-pin-medium-pin-content-leading-content-icon-selected: #282d33;
  --components-pin-medium-pin-content-leading-content-icon-pressed: #ffffff;
  --components-pin-medium-pin-content-leading-content-text-primary-default: #ffffff;
  --components-pin-medium-pin-content-leading-content-text-primary-selected: #282d33;
  --components-pin-medium-pin-content-leading-content-text-primary-pressed: #ffffff;
  --components-pin-medium-pin-content-leading-content-text-secondary-default: #ffffff;
  --components-pin-medium-pin-content-leading-content-text-secondary-selected: #282d33;
  --components-pin-medium-pin-content-leading-content-text-secondary-pressed: #ffffff;
  --components-pin-medium-pin-content-leading-content-loading-indicator-default-start: #282d33;
  --components-pin-medium-pin-content-leading-content-loading-indicator-default-end: #ffffff;
  --components-pin-medium-pin-content-leading-content-loading-indicator-selected-start: #282d33;
  --components-pin-medium-pin-content-leading-content-loading-indicator-selected-end: #ffffff;
  --components-pin-medium-pin-content-leading-content-loading-indicator-pressed-start: #282d33;
  --components-pin-medium-pin-content-leading-content-loading-indicator-pressed-end: #ffffff;
  --components-pin-medium-pin-content-middle-content-text-primary-default: #282d33;
  --components-pin-medium-pin-content-middle-content-text-primary-selected: #ffffff;
  --components-pin-medium-pin-content-middle-content-text-primary-pressed: #44505c;
  --components-pin-medium-pin-content-middle-content-text-secondary-selected: #ebf1f7;
  --components-pin-medium-pin-content-middle-content-text-secondary-default: #44505c;
  --components-pin-medium-pin-content-middle-content-text-secondary-pressed: #637485;
  --components-pin-medium-pin-content-middle-content-loading-indicator-default-start: #f7fafc;
  --components-pin-medium-pin-content-middle-content-loading-indicator-default-end: #dde6f0;
  --components-pin-medium-pin-content-middle-content-loading-indicator-selected-start: #dde6f0;
  --components-pin-medium-pin-content-middle-content-loading-indicator-selected-end: #637485;
  --components-pin-medium-pin-content-middle-content-loading-indicator-pressed-start: #f7fafc;
  --components-pin-medium-pin-content-middle-content-loading-indicator-pressed-end: #dde6f0;
  --components-pin-medium-pin-content-trailing-content-icon-default: #282d33;
  --components-pin-medium-pin-content-trailing-content-icon-selected: #ffffff;
  --components-pin-medium-pin-content-trailing-content-icon-pressed: #282d33;
  --components-pin-medium-pin-content-background-default: #ffffff;
  --components-pin-medium-pin-content-background-selected: #282d33;
  --components-pin-medium-pin-content-background-pressed: #f7fafc;
  --components-pin-medium-pin-content-border-default: rgba( #ffffff ,0.01);
  --components-pin-medium-pin-content-border-selected: rgba( #282d33 ,0.01);
  --components-pin-medium-pin-content-border-pressed: rgba( #ffffff ,0.01);
  --components-pin-medium-pointer-background-default: #282d33;
  --components-pin-medium-pointer-background-selected: #282d33;
  --components-pin-medium-pointer-background-pressed: #282d33;
  --components-pin-medium-pointer-border-default: #ffffff;
  --components-pin-medium-pointer-border-selected: #ffffff;
  --components-pin-medium-pointer-border-pressed: #ffffff;
  --components-pin-small-pin-content-background-default: #ffffff;
  --components-pin-small-pin-content-background-selected: #282d33;
  --components-pin-small-pin-content-background-pressed: #f7fafc;
  --components-pin-small-pin-content-border-default: rgba( #ffffff ,0.01);
  --components-pin-small-pin-content-border-selected: rgba( #282d33 ,0.01);
  --components-pin-small-pin-content-border-pressed: rgba( #ffffff ,0.01);
  --components-pin-small-pin-content-leading-content-background-default: #ffffff;
  --components-pin-small-pin-content-leading-content-background-selected: #282d33;
  --components-pin-small-pin-content-leading-content-background-pressed: #f7fafc;
  --components-pin-small-pin-content-leading-content-border-default: #ffffff;
  --components-pin-small-pin-content-leading-content-border-selected: #282d33;
  --components-pin-small-pin-content-leading-content-border-pressed: #f7fafc;
  --components-pin-small-pin-content-leading-content-icon-default: #282d33;
  --components-pin-small-pin-content-leading-content-icon-selected: #ffffff;
  --components-pin-small-pin-content-leading-content-icon-pressed: #282d33;
  --components-pin-small-pin-content-leading-content-text-primary-default: #ffffff;
  --components-pin-small-pin-content-leading-content-text-primary-selected: #282d33;
  --components-pin-small-pin-content-leading-content-text-primary-pressed: #ffffff;
  --components-pin-small-pin-content-leading-content-text-secondary-default: #ffffff;
  --components-pin-small-pin-content-leading-content-text-secondary-selected: #282d33;
  --components-pin-small-pin-content-leading-content-text-secondary-pressed: #ffffff;
  --components-pin-small-pin-content-leading-content-loading-indicator-default-start: #282d33;
  --components-pin-small-pin-content-leading-content-loading-indicator-default-end: #ffffff;
  --components-pin-small-pin-content-leading-content-loading-indicator-selected-start: #282d33;
  --components-pin-small-pin-content-leading-content-loading-indicator-selected-end: #ffffff;
  --components-pin-small-pin-content-leading-content-loading-indicator-pressed-start: #282d33;
  --components-pin-small-pin-content-leading-content-loading-indicator-pressed-end: #ffffff;
  --components-pin-small-pin-content-middle-content-text-primary-default: #282d33;
  --components-pin-small-pin-content-middle-content-text-primary-selected: #ffffff;
  --components-pin-small-pin-content-middle-content-text-primary-pressed: #44505c;
  --components-pin-small-pin-content-middle-content-text-secondary-default: #44505c;
  --components-pin-small-pin-content-middle-content-text-secondary-selected: #ebf1f7;
  --components-pin-small-pin-content-middle-content-text-secondary-pressed: #637485;
  --components-pin-small-pin-content-middle-content-loading-indicator-default-start: #f7fafc;
  --components-pin-small-pin-content-middle-content-loading-indicator-default-end: #dde6f0;
  --components-pin-small-pin-content-middle-content-loading-indicator-selected-start: #dde6f0;
  --components-pin-small-pin-content-middle-content-loading-indicator-selected-end: #637485;
  --components-pin-small-pin-content-middle-content-loading-indicator-pressed-start: #f7fafc;
  --components-pin-small-pin-content-middle-content-loading-indicator-pressed-end: #dde6f0;
  --components-pin-small-pin-content-trailing-content-icon-default: #282d33;
  --components-pin-small-pin-content-trailing-content-icon-selected: #ffffff;
  --components-pin-small-pin-content-trailing-content-icon-pressed: #282d33;
  --components-pin-small-pointer-background-default: #282d33;
  --components-pin-small-pointer-background-selected: #282d33;
  --components-pin-small-pointer-background-pressed: #282d33;
  --components-pin-small-pointer-border-default: #ffffff;
  --components-pin-small-pointer-border-selected: #ffffff;
  --components-pin-small-pointer-border-pressed: #ffffff;
  --illustration-careem-primary: #defcef;
  --illustration-careem-secondary: #00493e;
  --illustration-careem-tertiary: #ffffff;
  --illustration-careem-stroke: #002923;
}
